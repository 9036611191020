import Vue from 'vue';
import { testIsFunction, testIsObject } from '../test-and-assert/test-base';

class VueEventBus {
    constructor() {
        this.vue = new Vue();
    }

    /**
     * Emit an event by name. Optionally with a payload of any type
     * @param {String} eventName
     * @param {any} [payload]
     *
     * @return {Promise}
     */
    emit(eventName, payload) {
        return new Promise((resolve, reject) => {
            /**
             * @type {VueEventBus~eventData}
             */
            const eventData = {
                payload: payload,
                resolveEvent: resolve,
                rejectEvent: reject,
            };

            this.vue.$emit(eventName, eventData);
        });
    }

    /**
     * @callback VueEventBus~eventCallback
     * @param {any} [payload]
     */

    /**
     * @typedef {Object} VueEventBus~eventData
     * @property {any} payload
     * @property {Function} resolveEvent
     * @property {Function} rejectEvent
     */

    /**
     * Add event listener to the event bus. The callback function receives any payload that was emitted with the event
     * @param {String} eventName
     * @param {VueEventBus~eventCallback} callback
     */
    listen(eventName, callback) {
        this.vue.$on(eventName, (/** VueEventBus~eventData */ eventData) => {
            const response = callback(eventData.payload);

            if (!testIsObject(response) || !testIsFunction(response.then)) {
                eventData.resolveEvent(response);

                return;
            }

            response.then(eventData.resolveEvent).catch(eventData.rejectEvent);
        });
    }
}

if (!global.gEventBus) {
    global.gEventBus = new VueEventBus();
}

/**
 * @type {VueEventBus}
 */
const eventBus = global.gEventBus;

export { eventBus };
