import {
    assertIsBoolean,
    assertIsFunction,
    assertIsNumber,
    assertIsObject,
    assertIsTrue,
} from '../test-and-assert/assert-base';
import { testIsNonEmptyString, testIsObject, testIsString, testObjectHasKey } from '../test-and-assert/test-base';

import { globalLogger } from '../../qaamgo/helper/global-logger';

/**
 * Returns the delay until the next ajax retry based on the current number of retries.
 * The delay until the next timeout is capped at 15 seconds.
 *
 * @param currentRetry
 *
 * @return {number}
 */
var getSecondsUntilNextRetry = function (currentRetry) {
    assertIsNumber(currentRetry);

    if (currentRetry < 1) {
        currentRetry = 1;
    }

    // This is a very simple backoff algo, more retries -> longer delays
    var delayS = currentRetry;

    // The delay must be capped at some arbitrary value
    if (delayS > 20) {
        delayS = 20;
    }

    return delayS;
};

/**
 * Same as getSecondsUntilNextRetry but with longer delays. This should be used when the
 * server response is a 5xx. In such cases it makes sense to wait a bit, otherwise this
 * might result in a self-DDOS.
 *
 * @param currentRetry
 *
 * @return {number}
 */
var getSlowSecondsUntilNextRetry = function (currentRetry) {
    assertIsNumber(currentRetry);

    if (currentRetry < 1) {
        currentRetry = 1;
    }

    // Wait 10 seconds for the first few retries...
    if (currentRetry < 3) {
        return 10;
    }

    // ... otherwise wait 20 seconds
    return 20;
};

/**
 * @type {number}
 */
var AJAX_DEFAULT_TIMEOUT = 59000;

/**
 * @param {object}   [config]        Optional config object
 * @param {object}   [config.logger] If you need to use a different logger
 *
 * @constructor
 *
 * @deprecated
 */
function AjaxWrapper(config) {
    this._logger = globalLogger;

    if (testIsObject(config) && testObjectHasKey(config, 'logger')) {
        assertIsFunction(config.logger.log);
        this._logger = config.logger;
    }
}

/**
 * This is a drop-in replacement for $.ajax!
 *
 * It supports the same 'options' as $.ajax with the following additions:
 *
 * - Timout:
 *
 *   'options.timeout' sets the timeout for ajax requests in milliseconds.
 *
 *   By default there is no timeout in jquery.ajax(). In order to prevent problems we set
 *   the timeout to 59 seconds in the wrapper if no options.timeout exits. If you need
 *   more than 59 seconds you need to set a larger timeout.
 *
 * - Automatic retries:
 *
 *   The AjaxWrapper supports automatic retries. This can be enabled with:
 *
 *   options.maxRetries - set the number of retries
 *
 *   There are two options for the retry mechanism:
 *   a) options.retry400 - if true then requests with 4xx response are retried, default: false
 *   a) options.retry500 - if true then requests with 5xx response are retried, default: true
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions}  options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.ajax = function (options) {
    assertIsObject(options);

    var maxRetries = 0;

    if (options.hasOwnProperty('maxRetries')) {
        assertIsNumber(options.maxRetries);
        assertIsTrue(options.maxRetries >= 0);
        maxRetries = options.maxRetries;
    }

    if (options.hasOwnProperty('timeout')) {
        assertIsNumber(options.timeout);
        assertIsTrue(options.timeout >= 0);
    } else {
        options.timeout = AJAX_DEFAULT_TIMEOUT;
    }

    // 'type' and 'method' are the same thing but 'type' is deprecated. If only 'type' is
    // set then it is copied into method. The main reason to do this is for logging the
    // http method (see below)
    if (!options.hasOwnProperty('method') && options.hasOwnProperty('type')) {
        options.method = options.type;
    }

    // If method is not set then it defaults to 'GET' (this is the default value in jquery)
    if (!options.hasOwnProperty('method')) {
        options.method = 'GET';
    }

    var retryTimeout = false;
    var retry400 = false;
    var retry500 = true;
    var retryOther = true;

    if (options.hasOwnProperty('retryTimeout')) {
        assertIsBoolean(options.retryTimeout);
        retryTimeout = options.retryTimeout;
    }

    if (options.hasOwnProperty('retry400')) {
        assertIsBoolean(options.retry400);
        retry400 = options.retry400;
    }

    if (options.hasOwnProperty('retry500')) {
        assertIsBoolean(options.retry500);
        retry500 = options.retry500;
    }

    if (options.hasOwnProperty('retryOther')) {
        assertIsBoolean(options.retryOther);
        retryOther = options.retryOther;
    }

    // If 'cache' is not set by the user then it is disabled by default
    // (caching is enabled by default by jquery)
    if (!options.hasOwnProperty('cache')) {
        options.cache = false;
    }

    var nRetries = 0;

    var deferred = $.Deferred();

    var logger = this._logger;

    function doJqueryAjaxCall() {
        var startTime_ms = Date.now();

        /**
         * @param response
         * @param {string} textStatus
         * @param {JQueryXHR} jqXHR
         */
        function doneHandler(response, textStatus, jqXHR) {
            var duration_s = (Date.now() - startTime_ms) / 1000;

            if (duration_s > 15) {
                logger.log('qgAjaxWrapper - done - slow ajax call', {
                    url: options.url,
                    duration_s: duration_s,
                });
            }

            deferred.resolve(response, textStatus, jqXHR);
        }

        /**
         * @param {JQueryXHR} jqXHR
         * @param {number} jqXHR.status
         * @param {string} textStatus one of: null, "timeout", "error", "abort", "parsererror"
         * @param {?string} errorThrown might be null, or a text like "Internal Server Error."
         */
        function failHandlerRetries(jqXHR, textStatus, errorThrown) {
            var duration_s = (Date.now() - startTime_ms) / 1000;

            /**
             * @param {string} message
             */
            function _log(message) {
                logger.log(message, {
                    url: options.url,
                    ajax_status: textStatus,
                    ajax_error: errorThrown,
                    http_status: jqXHR.status,
                    http_method: options.method,
                    duration_s: duration_s,
                });
            }

            var isPaymentRequired = jqXHR.status === 402;

            // if payment required then retry does not make sense
            if (isPaymentRequired) {
                _log('qgAjaxWrapper - with retry - fail - 402 payment required');

                deferred.reject(jqXHR, textStatus, errorThrown);

                return;
            }

            var areRetriesLeft = nRetries < maxRetries;

            var isTimeout = textStatus === 'timeout';
            var is400 = jqXHR.status >= 400 && jqXHR.status < 500;
            var is500 = jqXHR.status >= 500 && jqXHR.status < 600;

            if (isTimeout) {
                if (retryTimeout && areRetriesLeft) {
                    _log('qgAjaxWrapper - with retry - fail - timeout - retry');
                } else {
                    _log('qgAjaxWrapper - with retry - fail - timeout - final');

                    deferred.reject(jqXHR, textStatus, errorThrown);

                    return;
                }
            } else if (is400) {
                if (retry400 && areRetriesLeft) {
                    _log('qgAjaxWrapper - with retry - fail - 4xx - retry');
                } else {
                    _log('qgAjaxWrapper - with retry - fail - 4xx - final');

                    deferred.reject(jqXHR, textStatus, errorThrown);

                    return;
                }
            } else if (is500) {
                if (retry500 && areRetriesLeft) {
                    _log('qgAjaxWrapper - with retry - fail - 5xx - retry');
                } else {
                    _log('qgAjaxWrapper - with retry - fail - 5xx - final');

                    deferred.reject(jqXHR, textStatus, errorThrown);

                    return;
                }
            } else {
                if (retryOther && areRetriesLeft) {
                    _log('qgAjaxWrapper - with retry - fail - other - retry');
                } else {
                    _log('qgAjaxWrapper - with retry - fail - other - final');

                    deferred.reject(jqXHR, textStatus, errorThrown);

                    return;
                }
            }

            nRetries += 1;

            var delayS = getSecondsUntilNextRetry(nRetries);

            // for 5xxs responses we use a longer delay
            if (is500) {
                delayS = getSlowSecondsUntilNextRetry(nRetries);
            }

            setTimeout(function () {
                doJqueryAjaxCall();
            }, delayS * 1000);
        }

        /**
         * This is the failure handler if no retries are requested
         *
         * @param {JQueryXHR} jqXHR
         * @param {number} jqXHR.status
         * @param {string} textStatus one of: null, "timeout", "error", "abort", "parsererror"
         * @param {?string} errorThrown might be null, or a text like "Internal Server Error."
         */
        function failHandlerNoRetries(jqXHR, textStatus, errorThrown) {
            var duration_s = (Date.now() - startTime_ms) / 1000;

            var message;

            var isTimeout = textStatus === 'timeout';
            const isPaymentRequired = jqXHR.status === 402;
            var is400 = jqXHR.status >= 400 && jqXHR.status < 500;
            var is500 = jqXHR.status >= 500 && jqXHR.status < 600;

            if (isTimeout) {
                message = 'qgAjaxWrapper - no retry - fail - timeout';
            } else if (isPaymentRequired) {
                message = 'qgAjaxWrapper - no retry - fail - 402 payment required';
            } else if (is400) {
                message = 'qgAjaxWrapper - no retry - fail - 4xx';
            } else if (is500) {
                message = 'qgAjaxWrapper - no retry - fail - 5xx';
            } else {
                message = 'qgAjaxWrapper - no retry - fail - other';
            }

            logger.log(message, {
                url: options.url,
                ajax_status: textStatus,
                ajax_error: errorThrown,
                http_status: jqXHR.status,
                http_method: options.method,
                duration_s: duration_s,
            });

            deferred.reject(jqXHR, textStatus, errorThrown);
        }

        if (typeof options.headers === 'undefined') {
            options.headers = {};
        }

        options.headers.it = 'b';

        $.ajax(options)
            .done(doneHandler)
            .fail(function (jqXHR, textStatus, errorThrown) {
                if (maxRetries > 0) {
                    failHandlerRetries(jqXHR, textStatus, errorThrown);
                } else {
                    failHandlerNoRetries(jqXHR, textStatus, errorThrown);
                }
            });
    }

    doJqueryAjaxCall();

    return deferred;
};

/**
 * This is a passthrough wrapper for jquery.post. See AjaxWrapper.ajax() for details
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions}  options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.post = function (options) {
    options.method = 'POST';

    return this.ajax(options);
};

/**
 * This is a wrapper for post() which makes sure that JSON data is correctly sent to the server
 *
 * If options.data is an object then it is converted to a json string. This simplifies
 * working with APIs
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions} options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.postJson = function (options) {
    options.method = 'POST';

    // set correct content type for json
    options.contentType = 'application/json';

    // Disable magic data processing which might result in url-encoding problems
    options.processData = false;

    // If 'options.data' is an object then it is auto-converted into a JSON string
    if (typeof options.data === 'object') {
        options.data = JSON.stringify(options.data);
    }

    return this.ajax(options);
};

/**
 * This is a passthrough wrapper for jquery.get. See AjaxWrapper.ajax() for details
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions} options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.get = function (options) {
    options.method = 'GET';

    return this.ajax(options);
};

/**
 * This is a passthrough wrapper for jquery.delete. See AjaxWrapper.ajax() for details
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions} options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.delete = function (options) {
    options.method = 'DELETE';

    return this.ajax(options);
};

/**
 * This is a passthrough wrapper for jquery.patch. See AjaxWrapper.ajax() for details
 *
 * @deprecated
 *
 * @param {AjaxWrapperOptions} options
 *
 * @return {JQuery.Deferred}
 */
AjaxWrapper.prototype.patch = function (options) {
    options.method = 'PATCH';

    return this.ajax(options);
};

/**
 * This returns some standard options which are used for 95-99% of the ajax requests
 *
 * @deprecated
 *
 * @param {string} [url]
 *
 * @return {AjaxWrapperOptions}
 */
AjaxWrapper.prototype.getDefaultOptions = function (url) {
    /** @type {AjaxWrapperOptions} */
    let options = {
        data: {},
        xhrFields: {
            withCredentials: true,
        },
        cache: false,
    };

    if (testIsNonEmptyString(url)) {
        options.url = url;
    }

    return options;
};

export { AjaxWrapper };
