import { testIsNonEmptyString, testIsObject, testIsString } from '../../../lib/test-and-assert/test-base';
import { globalLogger } from '../../helper/global-logger';
import { AjaxWrapper } from '../../../lib/ajax/ajax-wrapper';
import { satGlobals } from './sat-globals';

/**
 * This is a helper class which prevents multiple calls to the token endpoints
 *
 * - if a valid token was already received then it will be returned instead of making a new progress
 * - if a request for a token is already in progress, any additional function call will be stored and
 *   resolved later
 */
class MultithreadingHelper {
    constructor() {
        this.deferreds = {};
        this.tokens = {};
    }

    /**
     * @param {String} tokenType
     * @returns {boolean}
     */
    isRequestInProgress(tokenType) {
        // if no data exist then no request is in progress
        if (!this.deferreds.hasOwnProperty(tokenType)) {
            return false;
        }

        // if there are deferreds then a request is in progress
        return this.deferreds[tokenType].length !== 0;
    }

    /**
     * @param {String} tokenType
     * @param deferred
     */
    addDeferred(tokenType, deferred) {
        if (!this.deferreds.hasOwnProperty(tokenType)) {
            this.deferreds[tokenType] = [];
        }

        this.deferreds[tokenType].push(deferred);
    }

    /**
     * @param {String} tokenType
     * @param {String} token
     */
    resolve(tokenType, token) {
        this.tokens[tokenType] = token;

        /** @type {Array} */
        const deferreds = this.deferreds[tokenType];

        this.deferreds[tokenType] = [];

        deferreds.forEach((deffered) => {
            deffered.resolve(token);
        });
    }

    /**
     * @param {String} tokenType
     * @param {String} message
     */
    reject(tokenType, message) {
        /** @type {Array} */
        const deferreds = this.deferreds[tokenType];

        this.deferreds[tokenType] = [];

        deferreds.forEach((deffered) => {
            deffered.reject(message);
        });
    }

    /**
     * @param {String} tokenType
     * @returns {null|String}
     */
    getToken(tokenType) {
        if (this.tokens.hasOwnProperty(tokenType)) {
            return this.tokens[tokenType];
        }

        return null;
    }
}

const helper = new MultithreadingHelper();

function getCSrfToken(tokenType) {
    let deferred = $.Deferred();

    const maybeToken = helper.getToken(tokenType);

    /*
    if (typeof maybeToken === 'string') {
        deferred.resolve(maybeToken);

        return deferred;
    }
     */

    // if request is in progress then save deferred for later processing
    if (helper.isRequestInProgress(tokenType)) {
        helper.addDeferred(tokenType, deferred);

        return deferred;
    }

    // save the deferred so it can be resolved later
    helper.addDeferred(tokenType, deferred);

    const ajax = new AjaxWrapper();

    ajax.get({
        url: satGlobals.getApiUrl('api/csrf/') + tokenType,
        dataType: 'json',
        xhrFields: {
            withCredentials: true,
        },
        cache: false,
        maxRetries: 5,
    })
        .done(function (response) {
            if (response && response.token) {
                helper.resolve(tokenType, response.token);

                return;
            }

            globalLogger.log('get csrf token - fail - invalid response', {
                http_status: jqXHR.status,
                ajax_status: textStatus,
                ajax_error: errorThrown,
            });

            helper.reject(tokenType, 'invalid response');
        })
        .fail(function (jqXHR, textStatus, errorThrown) {
            globalLogger.log('get csrf token - fail - connection problem', {
                http_status: jqXHR.status,
                ajax_status: textStatus,
                ajax_error: errorThrown,
            });

            helper.reject(tokenType, 'connection problem');
        });

    return deferred;
}

class SatToken {
    constructor() {}

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfRegister() {
        return getCSrfToken('register');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfLogin() {
        return getCSrfToken('login');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfLogout() {
        return getCSrfToken('logout');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfContactUs() {
        return getCSrfToken('contact-us');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfPayment() {
        return getCSrfToken('payment');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfCloseAccount() {
        return getCSrfToken('close-account');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfCoupon() {
        return getCSrfToken('coupon');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfBillingInformation() {
        return getCSrfToken('billing-information');
    }

    /**
     * @deprecated
     * @return {JQuery.Deferred<String>}
     */
    getCsrfNotifications() {
        return getCSrfToken('notifications');
    }
}

/**
 * @deprecated
 * @type {SatToken}
 */
let satToken = new SatToken();

export { satToken };
