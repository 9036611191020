import {
    testIsArray,
    testIsEmptyString,
    testIsNonEmptyString,
    testIsNull,
    testIsNumber,
    testIsObject,
    testIsString,
    testIsTrimmedStringEmpty,
    testObjectHasKey,
} from '../../lib/test-and-assert/test-base';
import { getAssertMessage } from '../../lib/test-and-assert/get-assert-message';

/**
 * @param {MeData} meData
 *
 * @return {Boolean}
 */
function testIsMeData(meData) {
    // please note:
    // if the user is not logged in then /api/user/me returns only very few data
    // therefore we can only use a few fields to test if some random data is really
    // some real meData
    if (!testIsObject(meData)) {
        return false;
    }

    if (!testIsArray(meData.roles)) {
        return false;
    }

    if (!testIsString(meData.username)) {
        return false;
    }

    if (!testIsObject(meData.extra)) {
        return false;
    }

    return true;
}

export { testIsMeData };

function testIsEmailValid(email) {
    if (testIsEmptyString(email) || testIsNull(email)) {
        return false;
    }

    if (testIsTrimmedStringEmpty(email)) {
        return false;
    }

    const trimmedEmail = email.trim();

    // just check if the given email contains an '@' sign sandwiched between any characters
    // and ends with something that could pass as a domain if you squint hard enough
    const emailRegex = /^.+\@.+\..+$/;

    const validRegEx = emailRegex.test(trimmedEmail);

    const exactlyOneKlammeraffe = trimmedEmail.split('@').length === 2;

    return validRegEx && exactlyOneKlammeraffe;
}

export { testIsEmailValid };

function testIsUserSetting(setting) {
    return (
        testIsObject(setting) &&
        testObjectHasKey(setting, 'id') &&
        testObjectHasKey(setting, 'settings_key') &&
        testObjectHasKey(setting, 'settings_value') &&
        testObjectHasKey(setting, 'user') &&
        testIsNumber(setting.id) &&
        testIsNonEmptyString(setting.settings_key) &&
        testIsString(setting.settings_value) &&
        testIsNumber(setting.user)
    );
}

export { testIsUserSetting };

function testIsUserProduct(product) {
    return (
        testIsObject(product) &&
        testObjectHasKey(product, 'id') &&
        testObjectHasKey(product, 'name') &&
        testObjectHasKey(product, 'type') &&
        testObjectHasKey(product, 'description') &&
        testObjectHasKey(product, 'image') &&
        testObjectHasKey(product, 'brand_name') &&
        testObjectHasKey(product, 'created_at') &&
        testObjectHasKey(product, 'updated_at') &&
        testObjectHasKey(product, 'items') &&
        testObjectHasKey(product, 'constraints') &&
        testObjectHasKey(product, 'subscriptions') &&
        testIsNumber(product.id) &&
        testIsString(product.name) &&
        testIsString(product.type) &&
        testIsString(product.description) &&
        testIsString(product.image) &&
        testIsString(product.brand_name) &&
        testIsString(product.created_at) &&
        testIsString(product.updated_at) &&
        testIsArray(product.items) &&
        testIsObject(product.constraints) &&
        testIsArray(product.subscriptions)
    );
}

export { testIsUserProduct };
